import { Container, Grid } from '@material-ui/core';
import React from 'react'

import headerStyles from '../assets/scss/page-header.module.scss';
import GridRow from './_grid-row';


export default function PageHeader({header, desc}) {
    function checkData(cond, structureHTML, elseCond) {
        if(cond) {
            return structureHTML
        } else  {
            return elseCond? elseCond : '';
        }
    }

    return (
        <React.Fragment>
            <Container maxWidth="xl">
                <GridRow>
                    <Grid container spacing={3}>
                        <Grid item lg="6">
                            <header className={headerStyles.pageHeader}>
                                {checkData(
                                    (header),
                                    <h1 className={headerStyles.header}>{header}</h1>
                                )}
                                {checkData(
                                    (desc),
                                    <div className={headerStyles.desc} dangerouslySetInnerHTML={{__html: desc.replace(/\r\n|\r|\n/g,"<br />")}}></div>
                                )}
                            </header>
                        </Grid>
                    </Grid>
                </GridRow>
            </Container>
        </React.Fragment>
    )
}
