import React from 'react';
import { Link } from 'gatsby';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import cardStyles from '../assets/scss/bm-card.module.scss';

export default function BmCard({img, cardTitle, cardSubTitle, cardSmallSubtitle, desc, learnMore}) {
    const {title} = cardTitle ? cardTitle : '';
    const {titleLink} = cardTitle ? cardTitle : '';
    const {link} = learnMore ? learnMore : '';
    const {text} = learnMore ? learnMore : '';

    function checkData(cond, structureHTML, elseCond) {
        if(cond) {
            return structureHTML
        } else  {
            return elseCond? elseCond : '';
        }
    }
    return (
        <React.Fragment>
            <div className={cardStyles.bmCard}>
                {checkData(
                    (img !== ''),
                    <div className={cardStyles.imgHolder}>
                        <img src={img} className="img-fluid"/>
                    </div>
                )}
                
                {checkData(
                    (title),
                    <div className={cardStyles.titleWrapper}>
                        <h2>
                            {checkData(
                                (titleLink),
                                checkData(
                                    titleLink.includes('mailto'),
                                    <a href={titleLink}>{title}</a>,
                                    <Link to={titleLink}>{title}</Link>
                                ),
                                title
                            )}
                        </h2>
                        {checkData(
                            (cardSubTitle),
                            <h3 className={cardStyles.subtitle}>{cardSubTitle}</h3>,
                            ""
                        )}
                        {checkData(
                            (cardSmallSubtitle),
                            <h3 className={cardStyles.smallSubtitle}>{cardSmallSubtitle}</h3>,
                            ""
                        )}
                    </div>
                )}
                {checkData(
                    (desc), 
                    <div className={cardStyles.descWrapper}>
                        <div dangerouslySetInnerHTML={{__html: desc ? desc.replace(/\r\n|\r|\n/g,"<br />") : ''}}></div>
                    </div>,
                    ""
                )}

                {checkData(
                    (learnMore),
                    <div className={cardStyles.learnMoreWrapper}>
                        <Link to={link}>{text} <ArrowForwardOutlinedIcon /></Link>
                    </div>
                )}
            </div>
            
        </React.Fragment>
    )
}
