import { Container, Grid } from '@material-ui/core'
import React from 'react'
import BmCard from '../../components/bm-card'
import Layout from '../../components/layout'
import PageHeader from '../../components/page-header'
import GridRow from '../../components/_grid-row'

//education images
import made2move from '../../images/edu/made2move.png';
import the5amclub from '../../images/edu/the5amclub.png';
import breathwork from '../../images/edu/breathwork.png';
import greyPlaceholder from '../../images/grey-placeholder.png';

export default function Education() {
    return (
        <Layout pageName="Education">
            <PageHeader 
                header="Education"
                desc="At Beach Monkeyz, we believe that the road to higher transformation begins in the classroom. With our database of certified and passionate practitioners and coaches, we take pride in offering the following workshops and seminars brought to you every quarter, or pre-booked for a group of students."
            />
            
            <Container maxWidth="xl">
                <GridRow>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={made2move} 
                                cardTitle={{title: 'Made 2 Move', titleLink: '/education/made2move'}}  
                                desc="This interactive  workshop will guide you towards a deeper understanding of your body, the purpose of diverse movement complexity, and how to improve the quality of your life by simply being more…curious!"  
                                learnMore={{text: 'Learn more', link: '/education/made2move'}}
                            />
                        </Grid>
                        
                        {/* <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={greyPlaceholder}
                                cardTitle={{title: 'You Are What You Eat', titleLink: '/education/you-are-what-you-eat'}}  
                                desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sed mauris vitae ipsum volutpat blandit. Donec sit amet nulla mi. Aenean gravida, libero et vestibulum fermentum."  
                                learnMore={{text: 'Learn more', link: '/education/you-are-what-you-eat'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={greyPlaceholder}
                                cardTitle={{title: 'How to live a pain-free life', titleLink: '/education/how-to-live-a-pain-free-life'}}  
                                desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sed mauris vitae ipsum volutpat blandit. Donec sit amet nulla mi. Aenean gravida, libero et vestibulum fermentum."  
                                learnMore={{text: 'Learn more', link: '/education/how-to-live-a-pain-free-life'}}
                            />
                        </Grid> */}
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={the5amclub}
                                cardTitle={{title: 'The 5AM Club', titleLink: '/education/the5amclub'}}  
                                desc="Marianna is a passionate leader and developer of people, with 20+ years of experience in Marketing and Communications.Having worked in different settings in various countries in leading communication roles prior to joining Orascom as the Marketing and Communication Director. "  
                                learnMore={{text: 'Learn more', link: '/education/the5amclub'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={breathwork}
                                cardTitle={{title: 'Breathworks', titleLink: '/education/breathworks'}}  
                                desc="In this workshop we will discuss and practice, breathing techniques , movements and lifestyle practices from kundalini yoga that can use to prevent, survive and recover from a virus which attacks the respiratory system."  
                                learnMore={{text: 'Learn more', link: '/education/breathworks'}}
                            />
                        </Grid>
                    </Grid>
                </GridRow>
            </Container>
        </Layout>
    )   
}
